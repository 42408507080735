import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../services/auth.service';
import { RightPanelType } from '../../models/right-panel-types';
import { IAppConfig } from '../../models/app-config';
import { BRANDING } from '../../constants/branding';
import { LOGOBG } from '../../constants/logo_bg';
import * as CryptoJS from 'crypto-js'

@Component({
  selector: 'qtz-login',
  templateUrl: './qtz-login.component.html',
  styleUrls: ['./qtz-login.component.css'],
})
export class QtzLoginComponent implements OnInit {
  @Output()
  onEventAppConfig: EventEmitter<IAppConfig> = new EventEmitter();

  config: IAppConfig = {
    topbarTheme: 'dim',
    menuTheme: 'dim',
    layoutMode: 'dim',
    rotateMenuButton: false,
    overlayMenuActive: false,
    staticMenuDesktopInactive: false,
    staticMenuMobileActive: false,
    rightPanelActive: false,
    topbarItemClick: false,
    menuMode: 'static',
    isRTL: false,
    inputStyle: 'outlined',
    ripple: false,
    configActive: false,
    configClick: false,
    menuClick: false,
    activeTopbarItem: null,
    topbarMenuActive: false,
    rightPanelClick: false,
    rightPanelType: RightPanelType.FEED,
    menuHoverActive: false,
    searchClick: false,
    search: false,
    modalTutorial: false,
  };

  showPassword: boolean = false;

  public loginForm: FormGroup;
  public visitorId: string;
  private storage: Storage;
  public errorMessageLogin: string;
  public errorMessage: string;
  public successMessage: string;
  public showVerificateCode: boolean = false;
  public newUser: boolean = false;
  public newUserComplete: boolean = false;
  public loading: boolean;
  public mobile: boolean = false;
  public cripto_password: string;

  email: string;
  password: string;

  get layoutMode() {
    return this.config?.layoutMode;
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.storage = window.localStorage;
    this.loading = false;
  }

  ngOnInit(): void {
    const Token = window.localStorage.getItem('Token');
    if (Token) {
      this.router.navigateByUrl('/');
    }
  }

  hasErrors() {
    this.errorMessage = '';
    if (this.password.length < 1 || this.email.length < 1) {
      this.errorMessage = 'Usuário e/ou senha inválidos';
      return true;
    }

    return false;
  }

  onActionSwitch() {
    let event = this.config.layoutMode;
    if (this.config.layoutMode === 'dim') {
      event = 'light';
    } else {
      event = 'dim';
    }
    this.layoutModeChange(event);
  }

  showNewUserLayout() {
    this.errorMessage = '';
    this.newUser = true;
  }

  showOrHidePsswd() {
    this.showPassword = !this.showPassword;
  }

  gotToHomePage() {
    window.location.href = 'https://quantzed.com/home/#/';
  }

  gotToSendingCode() {
    this.router.navigateByUrl('/sending-code');
  }

  login() {
    this.errorMessage = '';
    this.successMessage = '';
    this.cripto_password = '';

    if (!this.hasErrors()) {
    
      let key = CryptoJS.enc.Utf8.parse('peixepikaquantze');
      let iv = CryptoJS.lib.WordArray.random(16);

      var cipherPassword = CryptoJS.AES.encrypt(
        this.password,
        key,
        {
          iv: iv,
        }
      );

      const resultPassword = iv
        .concat(cipherPassword.ciphertext)
        .toString(CryptoJS.enc.Base64);

      this.loading = true;
      this.authService
        .login(this.email, resultPassword, this.visitorId)
        .subscribe((res: any) => {
          this.loading = false;
          if (res.token) {
            console.log(res.token)
            const withOutSubdomain = window.location.hostname.replace(
              /^[^.]+\./g,
              ''
            );
            withOutSubdomain == 'quantzed.com.br'
              ? this.cookieService.set(
                  'Token',
                  res.token,
                  1,
                  '/',
                  '.quantzed.com.br'
                )
              : this.cookieService.set('Token', res.token);
            this.router.navigate(['']);
          }

          if (res?.error) {
            this.errorMessage = 'Usuário e/ou senha inválidos';
          }
        });
    }
  }

  get isDesktop() {
    return window.innerWidth > 1024;
  }

  get isMobile() {
    return window.innerWidth <= 640;
  }

  get topbarTheme() {
    return this.config?.topbarTheme;
  }

  get isDarkTheme() {
    return this.config?.layoutMode === 'dim';
  }

  get logo() {
    return BRANDING;
  }

  get logobg() {
    return `url(${LOGOBG})`;
  }

  layoutModeChange(theme: 'dim' | 'light') {
    this.config.layoutMode = theme;
    const layoutLink: HTMLLinkElement = document.getElementById(
      'layout-css'
    ) as HTMLLinkElement;
    const layoutHref = 'assets/layout/css/layout-' + theme + '.css';
    this.replaceLink(layoutLink, layoutHref);

    const themeLink = document.getElementById('theme-css');
    const urlTokens = themeLink?.getAttribute('href')?.split('/');

    if (urlTokens) {
      urlTokens[urlTokens.length - 1] = 'theme-' + theme + '.css';
      const newURL = urlTokens.join('/');
      this.replaceLink(themeLink, newURL);
    }
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  replaceLink(linkElement: any, href: string, callback?: any) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
      if (callback) {
        callback();
      }
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);

        if (callback) {
          callback();
        }
      });
    }
  }
}
