import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import jwt_decode from 'jwt-decode';
import { TokenDecode } from '../models/token';
import { AuthService } from './auth.service';
import { Login } from '../models/login';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
import { WHITE_LIST } from '../constants/login';

export class Permissions {
  async canActivate(
    auth: AuthService,
    router: Router,
    cookieService: CookieService,
    urls: { url: string; isExternal: boolean }
  ) {
    if (await this.authToken(router, auth, cookieService, urls)) {
      setInterval(async () => {
        const cookieToken = cookieService.get('Token');
      }, 30000);
      return true;
    }

    return false;
  }

  async authToken(
    router: Router,
    auth: AuthService,
    cookieService: CookieService,
    urls: { url: string; isExternal: boolean }
  ) {
    const cookieToken = cookieService.get('Token');
    if (cookieToken) {
      window.localStorage.setItem('Token', cookieToken);
      const decoded: TokenDecode = jwt_decode(cookieToken);

      if(decoded.accessTree.length === 0 && (decoded.isAdmin !== 1 && decoded.freePass !== 1)) {
        const withOutSubdomain = window.location.hostname.replace(
          /^[^.]+\./g,
          ''
        );
        window.localStorage.removeItem('Token');
        cookieService.set('Token', '', 1, '/', withOutSubdomain);
        cookieService.set('Token', '', 1, '/', 'localhost');
        cookieService.delete('Token');
        cookieService.deleteAll();
        this.redirectAuth(router, urls);

        return false;
      }

      // auth.verify().subscribe((res) => {
      //   if (res?.error) {
      //     const withOutSubdomain = window.location.hostname.replace(
      //       /^[^.]+\./g,
      //       ''
      //     );
      //     window.localStorage.removeItem('Token');
      //     cookieService.set('Token', '', 1, '/', withOutSubdomain);
      //     cookieService.set('Token', '', 1, '/', 'localhost');
      //     cookieService.delete('Token');
      //     cookieService.deleteAll();
      //     this.redirectAuth(router, urls);
      //   }
      // });

      if(!WHITE_LIST.includes(decoded.id)) {
        const withOutSubdomain = window.location.hostname.replace(
          /^[^.]+\./g,
          ''
        );
        window.localStorage.removeItem('Token');
        cookieService.set('Token', '', 1, '/', withOutSubdomain);
        cookieService.set('Token', '', 1, '/', 'localhost');
        cookieService.delete('Token');
        cookieService.deleteAll();
        this.redirectAuth(router, urls);
        return false
      }

      //IMPORTANTE * -> foi coloca um Math.random() para diminuir o renew de varias abas abertas ao msm tempo
      let dateToken = moment
        .unix(decoded.exp)
        .set({ millisecond: 0, second: 0 })
        .subtract(Math.random() * (10 - 2) + 2, 'minutes')
        .toDate()
        .getTime();
      let dateNow = moment()
        .set({ millisecond: 0, second: 0 })
        .toDate()
        .getTime();

      if (dateToken > dateNow) {
        return true;
      }

      const withOutSubdomain = window.location.hostname.replace(
        /^[^.]+\./g,
        ''
      );
      window.localStorage.removeItem('Token');
      cookieService.set('Token', '', 1, '/', withOutSubdomain);
      cookieService.set('Token', '', 1, '/', 'localhost');
      cookieService.delete('Token');
      cookieService.deleteAll();
      this.redirectAuth(router, urls);
      return false;
    }

    this.redirectAuth(router, urls);
    return false;
  }

  redirectAuth(
    router: Router,
    data: { url: string; isExternal: boolean }
  ): void {
    if (data.isExternal) {
      window.location.href = data.url;
    } else {
      router.navigate([data.url]);
    }
  }
}

@Injectable()
export class CanActivateByToken implements CanActivate {
  constructor(
    private cookieService: CookieService,
    private permissions: Permissions,
    private auth: AuthService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return await this.permissions.canActivate(
      this.auth,
      this.router,
      this.cookieService,
      { ...route.data['roles'] }
    );
  }
}
