import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QtzLoginComponent } from './shared/components/qtz-login/qtz-login.component';
import { CanActivateByToken, Permissions } from './shared/services/auth.guard.service';
import { AppComponent } from './app.component';
import { AppRoot } from './app-root.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [CanActivateByToken],
    component: AppRoot,
    data: {
      roles: {
        url: 'login',
        isExternal: false,
      },
    },
    children: [
      {
        path: '',
        component: AppComponent
      }
    ]
  },
  {
    path: 'login',
    component: QtzLoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [CanActivateByToken, Permissions],
  exports: [RouterModule],
})
export class AppRoutingModule { }

