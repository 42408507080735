import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { QtzLoginComponent } from './shared/components/qtz-login/qtz-login.component';
import { CookieService } from 'ngx-cookie-service';
import { AppRoot } from './app-root.component';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ngx-currency-mask';

@NgModule({
  declarations: [
    AppComponent,
    QtzLoginComponent,
    AppRoot,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [CookieService],
  bootstrap: [AppRoot]
})
export class AppModule { }
