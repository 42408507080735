import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import axios, { AxiosInstance } from 'axios';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import jwtDecode from 'jwt-decode';
@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  products: any[] = [];
  listProduct:any[] = [];
  listPayments:any[]= [];
  selectedProduct: string = '';
  selectedPayment: string = '';
  selectedJuros: Boolean = false;
  juros: boolean = false;
  isRenew: boolean = false;
  pickedJuros: string = 'Não';
  jurosList: any[] = ['Sim', 'Não'];
  code: string= '';
  due_date:  string= '';
  // customPrice: number;
  // customTitle: string;
  userId: number;
  linkCarrinho: string;
  userList: any[] = []
  searchUser: string = '';
  link: string = '';
  parcelas: number = 12;
  paymentsCode: string = ''
  payments_types: any[] = ["credit_card","pix","boleto"]
  axiosInstance: AxiosInstance = axios.create({
    baseURL: 'https://us-east1-qtzd-backoffice.cloudfunctions.net',
  });
  axionsInstance2: AxiosInstance = axios.create({
    baseURL: 'https://qtzd-auth-api-tky7rqhfva-ue.a.run.app'
  })
  userName = ''
  userEmail = ''
  userPhone = ''
  password = ''
  formulario: FormGroup;
  userListSubscription: Subscription;

  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
    
    this.formulario = this.formBuilder.group({
      customTitle : ['', [Validators.required]],
      customPrice: ['',  [Validators.required]]
    });
  }

  ngOnInit() {
    const inputPrice: HTMLInputElement = document.querySelector('input[name=price]');
    //this.formatCurrencyInput(inputPrice);
    

    this.getlistProducts().subscribe(
            response => {
              this.products = [...response.cursos, ...response.plataforma, ...response.extensoes];
            },
            error => {
              console.log('Erro ao obter os produtos:', error);
            }
          );
    this.getlistUsers(this.searchUser).subscribe(
        response => {
          this.userList = response;
        },
        error => {
          console.log('Erro do carai:', error);
        }
    )
    
  }

  getlistProducts() {
    return new Observable<any>((observer) => {
      this.axiosInstance
        .get(`/list_product_custom_cart?user_id=${this.getUserStorage()}`, {
          headers: { Authorization:  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFWFBJUkVTRUNPTkRTIjo2MDAwMDAwMDAwLCJleHAiOjc2ODgxNTU5NDIsInN0b3JlIjo0MDQxNTQ4LCJjb21wYW55IjoxLCJpYXQiOjE2ODgxNTU5NDJ9.iM1YFld3YKheJX2FyFvgB7O5W5Robx9Yrd6XvD6R1LQ" },
        })
        .then((res) => {
          observer.next(res.data);
          observer.complete();
        });
    });
  }
  getlistUsers(userString: string) {
    return new Observable<any>((observer) => {
      this.axiosInstance
        .get(`/list_users?search=${userString}`, {
          headers: { Authorization:  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFWFBJUkVTRUNPTkRTIjo2MDAwMDAwMDAwLCJleHAiOjc2ODgxNTU5NDIsInN0b3JlIjo0MDQxNTQ4LCJjb21wYW55IjoxLCJpYXQiOjE2ODgxNTU5NDJ9.iM1YFld3YKheJX2FyFvgB7O5W5Robx9Yrd6XvD6R1LQ" },
        })
        .then((res) => {
          observer.next(res.data);
          observer.complete();
        });
    });
  }
  refresh_users() {
    return new Observable<any>((observer) => {
      this.axiosInstance
        .get(`/copy_users`, {
          headers: { Authorization:  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFWFBJUkVTRUNPTkRTIjo2MDAwMDAwMDAwLCJleHAiOjc2ODgxNTU5NDIsInN0b3JlIjo0MDQxNTQ4LCJjb21wYW55IjoxLCJpYXQiOjE2ODgxNTU5NDJ9.iM1YFld3YKheJX2FyFvgB7O5W5Robx9Yrd6XvD6R1LQ" },
        })
        .then((res) => {
          observer.next(res.data);
          observer.complete();
        });
    });
  }
  getCartLink(cart) {
    console.log(cart)
    return new Observable<any>((observer) => {
      this.axiosInstance
        .post(`/create_personalized_order`,cart, {
          headers: { Authorization:  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFWFBJUkVTRUNPTkRTIjo2MDAwMDAwMDAwLCJleHAiOjc2ODgxNTU5NDIsInN0b3JlIjo0MDQxNTQ4LCJjb21wYW55IjoxLCJpYXQiOjE2ODgxNTU5NDJ9.iM1YFld3YKheJX2FyFvgB7O5W5Robx9Yrd6XvD6R1LQ" },
        })
        .then((res) => {
          observer.next(res.data);
          observer.complete();
        });
    });
  }

  createUser(user) {
    return new Observable<any>((observer) => {
      this.axiosInstance
        .post(`/fast_user`,user, {
          headers: { Authorization:  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFWFBJUkVTRUNPTkRTIjo2MDAwMDAwMDAwLCJleHAiOjc2ODgxNTU5NDIsInN0b3JlIjo0MDQxNTQ4LCJjb21wYW55IjoxLCJpYXQiOjE2ODgxNTU5NDJ9.iM1YFld3YKheJX2FyFvgB7O5W5Robx9Yrd6XvD6R1LQ" },
        })
        .then((res) => {
          observer.next(res.data);
          observer.complete();
        });
    });
  }
  getToken() {
    throw new Error('Method not implemented.');
  }
  addProduct(){
    if (!this.listProduct.includes(this.selectedProduct))
      this.listProduct.push(this.selectedProduct);
      this.concatStringCode()
  }
  addPayment(){
    if (!this.listPayments.includes(this.selectedPayment))
      this.listPayments.push(this.selectedPayment);
      this.concatStringCodePayment()
  }
  setJuros(event: any){
    this.juros = event.currentTarget.checked
  }
  setRenew(event: any){
    this.isRenew = event.currentTarget.checked
}
setDueDate(event: any){
  this.due_date = event.target.value
}
  generate_cart() {
    const cart = {
      renovacao: this.isRenew,
      value: parseFloat(this.formulario.get('customPrice').value.replace(",", ".")),
      products: this.code,
      titulo: this.formulario.get('customTitle').value,
      user_id: Number(this.userId),
      list_payments: this.listPayments,
      juros: this.juros,
      installments_number: Number(this.parcelas),
      due_date: String(this.due_date)
    };
    this.getCartLink(cart).subscribe(x=> this.link = x.url)
  }

  add_user() {
    const user = {
      name: this.userName,
      email: this.userEmail,
      phone: "999999999",
      cpf: "999999999",
      password: this.password
    };
    this.createUser(user).subscribe()
    this.userEmail = '';
    this.userName = '';
    this.password = '';
    this.refresh_users().subscribe()
    this.showMessage('Usuário cadastrado', 'Por favor, aguarde até 3 minutos para o usuário aparecer na listagem.', 'success', 'OK!')
    
  }
  
  concatStringCode(){
    this.code = this.listProduct.map(string => string).join(',');
  }

  concatStringCodePayment(){
    this.paymentsCode = this.listPayments.map(string => string).join(',');
  }

  generateCartLink() {
    const cartCode = this.products.map(product => product._id).join(',');
    console.log('Código do carrinho:', cartCode);
  }

  cleanProducts() {
    this.selectedProduct = '';
    this.listProduct = [];
    this.code = ''
  }

  handleSearchUser() {
    this.userListSubscription = this.getlistUsers(this.searchUser).subscribe(
      response => {
        this.userList = response;
      },
      error => {
        console.log('Erro do carai:', error);
      }
    )
    //this.userListSubscription.unsubscribe();
  }

  formatCurrencyInput(inputPrice: HTMLInputElement, currencySymbol: string = 'R$ '): void {
    inputPrice.value = currencySymbol + '0.00';
    inputPrice.addEventListener('keyup', () => {
      let initialPos = inputPrice.getAttribute('selectionStart');
      const rawValue = inputPrice.value.replace(/[^\d.]/g, '');
      const numericValue = parseFloat(rawValue);
      
      const formattedValue = this.formatCurrency(numericValue, currencySymbol);
      inputPrice.value = formattedValue;
      inputPrice.setSelectionRange(inputPrice.value.length, inputPrice.value.length);
    });
  }
  
  formatCurrency(value: number, currencySymbol: string = 'R$ '): string {
    const roundedValue = Math.ceil(value * 100)/100;

    const formattedValue = roundedValue
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${formattedValue}`;
  }

  showMessage(title, text, icon, button){
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: button
    })
  }

  copyLink() {
    const linkInput = document.getElementById('generated-link') as HTMLInputElement;
    linkInput.select();
    document.execCommand('copy');
  }

  getUserStorage(): number {
    const token_raw =localStorage.getItem('Token')
    let decode
    try{
      decode = jwtDecode(token_raw)
    } catch(e) {
      return
    }

    return decode['id']

  }
}
