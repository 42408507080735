import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Login } from '../models/login';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AUTH_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  visitorId = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
  ) {}

  login(
    username: string,
    password: string,
    fingerprint: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    return this.http
      .post(`${AUTH_URL}/securityauth`, formData)
      .pipe(retry(2), catchError(this.handleError));
  }

  renew(): Observable<Login> {
    return this.http.post<Login>(`${AUTH_URL}/renew`, {});
  }

  verify(): Observable<any> {
    return this.http.post<any>(`${AUTH_URL}/verify`, {});
  }

  sendCode(email: string): Observable<any> {
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post<any>(
      `${AUTH_URL}/forgot`,
      formData
    );
  }

  resetPassword(username: string, password: string, secrety: string) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('newpassword', password);
    formData.append('secrety', secrety);
    return this.http.post<any>(
      `${AUTH_URL}/reset`,
      formData
    );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error code: ${error.status} / Message: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
