<img class="logo-img" src="https://storage.googleapis.com/qtz-files/site/logo_principal_black_november.png" alt="Logo" style="width:10%">
<!-- <img class="poweredby" src="https://imgflip.com/i/7renjz"> -->
<!-- ="https://storage.googleapis.com/qtz-files/site/logo_principal_black_november.png" -->
<section class="custom-cart-section">
  <div style="display: flex; align-items: center; justify-content: center;">
    <h2>Carrinho Personalizado</h2>
    <iframe src="https://embed.lottiefiles.com/animation/111644" frameBorder="0" class="animated-icon"></iframe>
  </div>
  
  <div class="form-group">
    <label for="product">Produtos:</label>
    <select
    class="select-product"
      name="product"
      [(ngModel)]="selectedProduct"
      (change)="addProduct()"
      [ngModelOptions]="{ standalone: true }"
    >
      <option value="">Selecione um ou mais produtos</option>
      <option *ngFor="let product of products" [value]="product._id">
        {{ product.name }}
      </option>
    </select>
  </div>
  
  <div class="form-group" style="margin-right: 24px">
    <label for="title">CustomCode:</label>
    <div style="display: flex; flex-direction: row; gap: 16px">
      <input
        type="text"
        [disabled]="true"
        name="code"
        [(ngModel)]="code"
        [ngModelOptions]="{ standalone: true }"
      />
      <a (click)="cleanProducts()" style="cursor: pointer; margin-top: 6px">Limpar</a>
    </div>
  </div>
  <form [formGroup]="formulario">
    <div class="form-group" style="margin-right: 24px">
      <label for="price">Preço customizado:</label>
      <input
        id="currencyInput"
        inputmode="numeric"
        formControlName="customPrice"
        name="price"
        id="price"
        mask="separator.2"
        thousandSeparator="."
      />
      <div *ngIf="(formulario.get('customPrice').touched || formulario.get('customPrice').dirty) && formulario.get('customPrice')?.errors" class="validation-error-message">
        Preenche o preço aii mano
      </div>
    </div>
  
    <div class="form-group" style="margin-right: 24px">
      <label for="title">Título customizado:</label>
      <input type="text" name="title" formControlName="customTitle" />
      <div *ngIf="(formulario.get('customTitle').touched || formulario.get('customTitle').dirty)  && formulario.get('customTitle')?.errors" class="validation-error-message">
        Coloca o titulo ai parça
      </div>
    </div>
    
    <div class="form-group">
      <label>Nome do usuário</label>
      <div style="display: flex; flex-direction: row; gap: 16px">
        <input
          type="text"
          name="searchUser"
          [(ngModel)]="searchUser"
          (change)="handleSearchUser()"
          [ngModelOptions]="{ standalone: true }"
        />
        <select
          name="userId"
          [(ngModel)]="userId"
          [ngModelOptions]="{ standalone: true }"
        >
          <option>Selecione o nome do usuário</option>
          <option *ngFor="let user of userList" [value]="user.id">
            {{ user.id }} - {{ user.name }} - {{ user.email }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <div class="form-group">
        <div style="display: flex; flex-direction: row; gap: 12px">
        <label for="pagamentos">Formas de Pagamento:</label>
        <select
          name="pagamentos"
          [(ngModel)]="selectedPayment"
          (change)="addPayment()"
          [ngModelOptions]="{ standalone: true }"
        >
          <option value="">Selecione um ou mais meios de pagamento</option>
          <option *ngFor="let payment of payments_types" [value]="payment">
            {{ payment }}
          </option>
        </select>
        <label for="title">Pagamentos selecionados:</label>
        <input
          type="text"
          [disabled]="true"
          name="pagamentos"
          [(ngModel)]="paymentsCode"
          [ngModelOptions]="{ standalone: true }"
        />
        <label for="price">Parcelas:</label>
        <input
          id="currencyInput"
          value = 12
          type="number"
          min="1" max="12"
          [(ngModel)]="parcelas"
          [ngModelOptions]="{ standalone: true }"
          name="parcelas"
          onKeyDown="return false"
          
        />
        <label>
          Juros:
          <input type="checkbox" [(ngModel)]="juros" (change)="setJuros($event)" [ngModelOptions]="{standalone: true}">
        </label>
        <label>
          Renovação:
          <input type="checkbox" [(ngModel)]="isRenew" (change)="setRenew($event)" [ngModelOptions]="{standalone: true}">
        </label>
      </div>
        <div>
          <label>
            Data de vencimento do boleto:
            <input type="date" [(ngModel)]="due_date" id="due_date" [ngModelOptions]="{standalone: true}" (change)="setDueDate($event)">
          </label>
        </div>
      </div>
    </div>
    
    
  
    <button type="submit"[disabled]="formulario.invalid" (click)="generate_cart()">Gerar Carrinho Personalizado</button>
  </form>
  
  <br />
  <br />

  <div class="form-group suruba">
    <label for="title">LINK GERADO:</label>
    <div style="display: flex; gap: 16px; justify-content: center;">
      <input
        type="text"
        [disabled]="true"
        name="link"
        [(ngModel)]="link"
        style="width: 50%"
        id="generated-link"
      />
      <button class="copy-button" (click)="copyLink()">Copiar</button>
    </div>
  </div>
</section>

<div class="abstract-background"></div>

<img src="https://storage.googleapis.com/qtz-files/plataforma/assets/Logo/qtzd_symbol.svg" alt="bg-logo" class="bg-logo">

<section class="custom-cart-section">
  <div style="display: flex; align-items: center; justify-content: center;">
    <h2>Criar usuário basico</h2>
    <iframe src="https://embed.lottiefiles.com/animation/17726" frameBorder="0" class="animated-icon-biggest"></iframe>
  </div>
  <label>Nome do usuário</label>
  <div style="display: flex; flex-direction: row; gap: 8px">
    <input
      type="text"
      name="name"
      [(ngModel)]="userName"
      [ngModelOptions]="{ standalone: true }"
    />
  </div>
    <label>E-mail</label>
    <div style="display: flex; flex-direction: row; gap: 16px">
      <input
        type="text"
        name="email"
        [(ngModel)]="userEmail"
        [ngModelOptions]="{ standalone: true }"
      />
      </div>
      
    
      <label>Senha</label>
      <div style="display: flex; flex-direction: row; gap: 16px">
        <input
          type="text"
          name="password"
          [(ngModel)]="password"
          [ngModelOptions]="{ standalone: true }"
        />
        </div>
      <button type="submit" (click)="add_user()">Cadastrar Usuário</button>

</section>