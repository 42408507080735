<div
  style="height: 100%"
  [style.background-image]="mobile ? logobg : ''"
>
  <div
  style="padding: 16px; height: 100vh; overflow: hidden;"
  [style.background-color]="isDarkTheme"
  class="container-login"
  >
    <img class="logo-img" src="https://storage.googleapis.com/qtz-files/site/logo_principal_black_november.png" alt="Logo" style="width:25%">
    <b class="word-welcome">Bem-vindo</b>
    <div
      class="container-form"
    >
      <label class="form-label"><b>E-mail</b></label>
      <input
        class="form-input login-form-input"
        type="email"
        name="email"
        style="margin-bottom: 24px"
        [(ngModel)]="email"
        (keyup.enter)="login()"
      />
    </div>
    <div
      class="container-form"
    >
      <label class="form-label"><b>Senha</b></label>
        <input
          class="form-input login-form-input"
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          [(ngModel)]="password"
          (keyup.enter)="login()"
        />
    </div>
    <a class="submit-bttn" (click)="login()">Entrar</a>
  </div>
</div>
